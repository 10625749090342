<template>
  <v-container class="pt-0 pb-15">
    <div
      style="display: flex; flex-drection: row; align-items: center; justify-content: space-between; margin-bottom: 10px;"
    >
      <span
        style="color: #6038F7; font-size: 20px; font-family: 'Poppins-Medium'; margin-top: 15px;"
      >
        Business
      </span>
      <v-btn color="#B5E539" small @click="onCreateBusiness" class="mt-3">
        Create
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="business"
      :items-per-page="15"
      :loading="loading"
      @click:row="onSelectClient"
      class="elevation-0"
    >
      <template v-slot:[`item.image`]="{ item }">
        <editable-avatar
          :image="item.image"
          :editable="false"
          :size="50"
          class="my-3"
        />
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <div>
          <div>
            {{ item.address
            }}{{ item.address2 ? ", " + item.address2 + "" : "" }}
          </div>
          <div>{{ item.city }}, {{ item.state }} {{ item.zip }}</div>
        </div>
      </template>
      <template v-slot:[`item.about`]="{ item }">
        <div class="text-block">{{ item.about }}</div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                small
                class="ml-2"
                @click.stop="onEditBusiness(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            Edit
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                small
                class="ml-2"
                @click.stop="onDeleteBusiness(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            Delete
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <confirm-delete-dialog
      :deleteDialog="deleteDialg"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <admin-add-business-dialog
      :dialog="addDialog"
      :onClose="onCloseDialog"
      v-if="addDialog"
    />
    <admin-edit-business-dialog
      :dialog="editDialog"
      :onClose="onCloseDialog"
      :business="selected"
      v-if="editDialog"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import dateFormat from "dateformat";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import AdminAddBusinessDialog from "./AdminAddBusinessDialog.vue";
import AdminEditBusinessDialog from "./AdminEditBusinessDialog.vue";
export default {
  components: {
    EditableAvatar,
    ConfirmDeleteDialog,
    AdminAddBusinessDialog,
    AdminEditBusinessDialog,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "image" },
        { text: "Name", value: "name" },
        { text: "About", value: "about" },
        { text: "Website", value: "website" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions" },
      ],
      deleteDialg: false,
      selected: null,
      expanded: [],
      editDialog: false,
      addDialog: false,
    };
  },
  methods: {
    ...mapActions("business", {
      fetchAllBusiness: "fetchAllBusiness",
      deleteBusiness: "deleteBusiness",
    }),
    onSelectClient(item) {
      console.log(item);
    },
    formatDate(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "mm/dd/yyyy");
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    onEditBusiness(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onCloseDialog() {
      this.addDialog = false;
      this.editDialog = false;
    },
    onDeleteBusiness(item) {
      this.selected = item;
      this.deleteDialg = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialg = false;
    },
    onConfirmDelete() {
      this.deleteDialg = false;
      this.loading = true;
      this.deleteBusiness({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    onCreateBusiness() {
      this.addDialog = true;
    },
  },
  computed: {
    ...mapGetters({
      business: "business/getAllBusiness",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone",
    }),
  },
  mounted() {
    if (this.profileType != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.loading = true;
    this.fetchAllBusiness()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.page-title {
  color: #472583;
  font-size: 40px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
</style>
